import React from 'react'
import image from '../../assets/images/careers/team-building-website.svg'
import imageTwo from '../../assets/images/careers/happy-people.svg'

const CompanyBenefitsSection = ({blurb, blurbTwo, Image, title}) => {
  return(
    <section className='container w-full mt-4 md:mt-6 pb-8 lg:pb-12 px-6'>
      <div className='flex flex-col md:justify-evenly mx-0 mt-4'>
        <div className='mt-4 flex grid-cols-2 text-center'>
          <div>
            <h3>The Way We Work</h3>
            <p>{blurb}</p>
          </div>
          <img src={Image} className='hidden md:block w-20vw pl-8' alt='unknown' />
        </div>
        <div className='mt-4 flex grid-cols-2 text-center'>
          <img src={imageTwo} className='hidden md:block w-20vw pr-8' alt='add me' />
          <div>
            <h3>{title}</h3>
            <p>{blurbTwo}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

CompanyBenefitsSection.defaultProps = {
  blurb: 'Our core philosophy is morality over money. At We Do Code we encourage independent decision making by employees, share information openly, broadly and deliberately, drive highly effective people, avoid rules and are extraordinarily candid with one another. The more these attributes appeal to you, the more likely you are to thrive with us. The objective is to grow a ‘dream team’ here at We Do code; a dream team is one in which all of your colleagues are extraordinary at what they do and are highly effective collaborators. The value and satisfaction of being on a dream team is tremendous.',
  blurbTwo: 'Our version of the great workplace is not sushi lunches, great gyms, fancy offices, or frequent parties. Our version of the great workplace is a dream team in pursuit of ambitious common goals, for which we spend heavily. It is on such a team that you learn the most, do your best work, improve the fastest and have the most fun. We do not seek to preserve our culture, we seek to improve it. Every person who joins us helps to shape and evolve the culture further. We find new ways to accomplish more together. ',
  Image: image,
  title: 'Love your job',
}

export default CompanyBenefitsSection