import React from 'react'
import image from '../../assets/images/careers/interview-image.svg'

const VacanciesSection = () => {
  return(
    <section className='container w-full overflow-hidden text-center mt-4 md:mt-6 pb-8 lg:pb-12 px-6'>
      <h3>Current Vacancies</h3>
      <div className='flex grid-cols-2 gap-4 mt-4 md:mt-12 justify-evenly'>
        <div className='flex flex-col text-center'>
          <p>Unfortunately we do not have any vacancies at the moment.  If you would like to send your CV in for any potential future roles that come available, please email <a href='mailto:careers@wedocode.co.uk' className='normal-case underline' title='careers@wedocode.co.uk'>careers@wedocode.co.uk</a>.</p>
        </div>
        <div>
          <img src={image}
               alt='A person handing a file across a desk to another person, viewed from above'
               className='hidden md:block w-20vw'
          />
        </div>
      </div>
    </section>
  )
}

export default VacanciesSection