import React from 'react'
import ArticlesSection from '../components/sections/ArticlesSection'
import CompanyBenefitsSection from '../components/sections/CompanyBenefitsSection'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import VacanciesSection from '../components/sections/VacanciesSection'
import WeDoCtoBanner from '../components/sections/WeDoCtoBanner'

const Careers = ({
  data: {
    contentfulPage: pageData,
    allContentfulArticle: { nodes: articlesData },
  },
}) => {
  return (
    <Layout page={pageData}>
      <CompanyBenefitsSection />
      <VacanciesSection />
      {/*<WeDoCtoBanner />*/}
      <ArticlesSection title='Latest' articlesData={articlesData} />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulPage(pageLink: { eq: "/careers" }) {
      ...pageFields
    }
    allContentfulArticle(limit: 3, sort: { fields: publishDate, order: DESC }) {
      nodes {
        ...articleFields
      }
    }
  }
`

export default Careers
